<template>
  <div>
    <el-card>
      <!-- 搜索 添加 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="queryInfo.query" placeholder="请输入公司名称"  >
            <el-button slot="append" icon="el-icon-search" @click="getTableData"></el-button>
          </el-input>
        </el-col>
        <!-- <el-col :span="2">
          <el-select v-model="queryInfo.querySelectInput" @change="getTableData" placeholder="条件选择">
            <el-option
              v-for="item in querySelectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col> -->
        <el-col :span="6">
            <el-date-picker
                v-model="value1"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="至"
                @change="getTableData()"
                start-placeholder="最终处理开始日期"
                end-placeholder="最终处理结束日期">
            </el-date-picker>
        </el-col>
        <!-- <el-col :span="6">
          <el-button type="primary" @click="comfirmGics" :disabled="isDisabledGics">任务发起</el-button>
        </el-col> -->
        <el-col :span="6">
          <el-button type="primary" @click="comfirmGics" :disabled="isDisabledGics">任务查看</el-button>
        </el-col>
      </el-row>
      <!-- 用户列表区域 -->
      <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="company_ID"
          label="公司ID"
          width="150">
        </el-table-column>
        <el-table-column
          prop="company_NAME"
          label="公司名称"
          width="350">
        </el-table-column>
        <el-table-column
          prop="fiscal_YEAR"
          label="财报年份"
          width="140">
        </el-table-column>
        <el-table-column
          prop="CREATE_TIME"
          label="创建时间"
          width="150">
        </el-table-column>
        <el-table-column
          prop="PROCESS_DATE"
          label="最终处理日期"
          width="150">
        </el-table-column>
        <el-table-column
          prop="city_INVEST_NAME"
          label="是否城投"
          width="150">
        </el-table-column>
        <el-table-column
          prop="task_STATUS_NAME"
          label="任务状态"
          width="150">
        </el-table-column>
        <el-table-column
          prop="process_OPINION"
          label="意见"
          width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">变更记录</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
    </el-card>
    <!-- 添加用户的对话框 -->
    <el-dialog title="主体信息修改" :visible.sync="addDialogVisible" width="50%"
      :before-close="closeRegDialog">
      <el-form ref="addChangeForm" :model="addChangeForm" label-width="100px" :rules="rules" class="demo-ruleForm" >
        <el-form-item label="公司名称"  >
           <el-col :span="11">
            {{addChangeForm.company_NAME}}
           </el-col>
        </el-form-item>
        <el-form-item label="企业性质">
          <el-col :span="11">
            {{addChangeForm.org_TYPE_NAME}}
          </el-col>
        </el-form-item>
        <el-form-item label="财报年份" >
          {{addChangeForm.fiscal_YEAR}}
        </el-form-item>
        <el-form-item label="上年行业" >
          {{addChangeForm.last_GICS_NAME}}
        </el-form-item>
        <el-form-item label="财报行业" prop="gics_CODES"  >
          <div class="block">
            <el-cascader :show-all-levels="false"
            placeholder="搜索"
            v-model="addChangeForm.gics_CODES"
            :options="fiscalOptions"
            :disabled = true
            filterable></el-cascader>
        </div>
        </el-form-item>
        <el-form-item label="主体归属" >
          <div class="block">
            <el-cascader :show-all-levels="false"
              placeholder="请选择"
              v-model="addChangeForm.regions"
              :props="{ checkStrictly: true }"
              :disabled = true
              :options="regionOptions"></el-cascader>
          </div>
        </el-form-item>
        <el-form-item label="城投核查">
          <el-checkbox v-model="addChangeForm.check_CITY_INVEST" :disabled = true>是否核查</el-checkbox>
        </el-form-item>
        <el-form-item label="城投变更" prop="change_CITY_INVEST">
          <el-select v-model="addChangeForm.change_CITY_INVEST"  :disabled = true placeholder="请选择">
            <el-option
              v-for="item in violateChangeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="城投任务" prop="invest_TASK">
          <el-select v-model="addChangeForm.invest_TASK"  placeholder="请选择">
            <el-option
              v-for="item in violateChangeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否城投" prop="city_INVEST">
          <el-select v-model="addChangeForm.city_INVEST" :disabled = true placeholder="请选择">
            <el-option
                v-for="item in violateOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item label="城投变更" prop="change_CITY_INVEST">
            <el-select v-model="addChangeForm.change_CITY_INVEST"  placeholder="请选择">
                <el-option
                v-for="item in violateChangeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </el-form-item> -->
        <div v-if="addChangeForm.city_INVEST==1" >
          <el-form-item label="政府归属" prop="region_CODES" >
            <div class="block">
            <el-cascader :show-all-levels="false"
                placeholder="请选择"
                :disabled = true
                v-model="addChangeForm.region_CODES"
            :props="{ checkStrictly: true }"
                :options="regionOptions"></el-cascader>
            </div>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <el-row>
              <el-col :span="10">支持维度</el-col>
              <el-col :span="6">支持程度</el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  公益性程度
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value">
                  <el-select v-model="addChangeForm.spt_level_value" :disabled = true @change="selectSptLevel" placeholder="请选择">
                    <el-option
                      v-for="item in spt_level_options"
                      :score="item.support_SCORE"
                      :key="item.support_LEVEL_ID"
                      :label="item.support_LEVEL_TITLE"
                      :value="item.support_LEVEL_ID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  股权结构
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value2">
                  <el-select v-model="addChangeForm.spt_level_value2" :disabled = true @change="selectSptLevel2" placeholder="请选择">
                    <el-option
                      v-for="item in spt_level_options2"
                      :score="item.support_SCORE"
                      :key="item.support_LEVEL_ID"
                      :label="item.support_LEVEL_TITLE"
                      :value="item.support_LEVEL_ID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  政府补助占营业利润总额的比重
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value3">
                  <el-select v-model="addChangeForm.spt_level_value3" :disabled = true @change="selectSptLevel3" placeholder="请选择">
                    <el-option
                      v-for="item in spt_level_options3"
                      :score="item.support_SCORE"
                      :key="item.support_LEVEL_ID"
                      :label="item.support_LEVEL_TITLE"
                      :value="item.support_LEVEL_ID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <el-form-item>
                  增信措施
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item prop="spt_level_value4">
                  <el-select v-model="addChangeForm.spt_level_value4" :disabled = true @change="selectSptLevel4" placeholder="请选择">
                    <el-option
                        v-for="item in spt_level_options4"
                        :score="item.support_SCORE"
                        :key="item.support_LEVEL_ID"
                        :label="item.support_LEVEL_TITLE"
                        :value="item.support_LEVEL_ID">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            </el-form-item>
          <el-form-item label="总分">{{addChangeForm.support_SCORE_All}}</el-form-item>
        </div>
        <!-- <el-form-item>
          <el-button v-if="!isDisabledGics || !isDisabledVio" type="primary" @click="lunch('addChangeForm')">发起审核</el-button>
          <el-button v-if="!isTaskConfirm" type="primary" @click="lunch('addChangeForm')">提交审核</el-button>
          <el-button @click="cancle">取消</el-button>
        </el-form-item> -->
      </el-form>
    </el-dialog>
    <el-dialog title="变更记录" :visible.sync="recordDialogVisible" width="50%" >
       <el-table
        :data="tableDataHistory"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          prop="fiscal_YEAR"
          label="财报年份"
          width="100">
        </el-table-column>
        <el-table-column
          prop="gics_NAME"
          label="财报行业"
          width="140">
        </el-table-column>
        <el-table-column
          prop="is_PD"
          label="是否违约"
          width="140">
        </el-table-column>
        <el-table-column
          prop="city_INVEST"
          label="是否城投"
          width="140">
        </el-table-column>
        <el-table-column
          prop="region_CODES"
          label="政府归属"
          width="140">
        </el-table-column>
        <el-table-column
          prop="spt_SCORE"
          label="政府支持得分"
          width="110">
        </el-table-column>
       </el-table>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'CompanyAdditionalHistory',
  methods: {
    async getTableData () {
      if (this.value1) {
        this.queryInfo.beginDate = this.value1[0]
        this.queryInfo.ednDate = this.value1[1]
      } else {
        this.queryInfo.beginDate = ''
        this.queryInfo.ednDate = ''
      }
      const { data: res } = await this.$http.get('/querygics/pass/task', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
      this.rowData = null
      this.addChangeForm.city_INVEST = null
    },
    async lunch (addChangeForm) {
      let istrue
      // form验证
      this.$refs[addChangeForm].validate((valid) => {
        if (valid) {
          istrue = true
        } else {
          return false
        }
      })
      if (istrue) {
        this.addChangeForm.gics_CODE = this.addChangeForm.gics_CODES[3]
        const { data: res } = await this.$http.post('lunch', this.addChangeForm)
        if (res.have) {
          this.$message.success('该任务已被别的用户提交')
        }
        this.addDialogVisible = false
        this.getTableData()
      }
    },
    async getGics () {
      const { data: res } = await this.$http.get('gics')
      this.fiscalOptions = res
    },
    async getRegion () {
      const { data: res } = await this.$http.get('region')
      this.regionOptions = res
    },
    async selectLevel () {
      const { data: res } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 10 }
      })
      this.spt_level_options = res
      const { data: res2 } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 20 }
      })
      this.spt_level_options2 = res2
      const { data: res3 } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 30 }
      })
      this.spt_level_options3 = res3
      const { data: res4 } = await this.$http.get('get/spt/level', {
        params: { support_index_id: 40 }
      })
      this.spt_level_options4 = res4
    },
    async selectSptLevel (value) {
      const spt = this.spt_level_options
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    async selectSptLevel2 (value) {
      const spt = this.spt_level_options2
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE2 = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    async selectSptLevel3 (value) {
      const spt = this.spt_level_options3
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE3 = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    async selectSptLevel4 (value) {
      const spt = this.spt_level_options4
      for (const iterator of spt) {
        if (iterator.support_LEVEL_ID === value) {
          this.addChangeForm.support_SCORE4 = iterator.support_SCORE
        }
      }
      this.addChangeForm.support_SCORE_All = this.sumSupportScore
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    },
    // 确认行业
    async comfirmGics () {
      // 行业数据赋值
      this.getGics()
      this.getRegion()
      if (this.rowData == null) {
        this.$alert('请选择一条公司信息', '信息选择').then(() => {
        }).catch(() => {
        })
        return
      }
      this.selectLevel()
      // 根据公司ID和财报年份查询或者根据任务ID查询
      const { data: res } = await this.$http.get('/query/info/by/id', {
        params: {
          task_ID: this.addChangeForm.task_ID,
          company_ID: this.addChangeForm.company_ID,
          fiscal_YEAR: this.addChangeForm.fiscal_YEAR
        }
      })
      if (res !== '' && res !== null) {
        this.addChangeForm.gics_CODES = res.gics_CODES
        this.addChangeForm.region_CODES = res.region_CODES
        this.addChangeForm.regions = res.regions
        this.addChangeForm.org_TYPE_NAME = res.org_TYPE_NAME
        if (res.change_CITY_INVEST !== null && res.change_CITY_INVEST !== '') {
          this.addChangeForm.change_CITY_INVEST = res.change_CITY_INVEST + ''
        } else {
          this.addChangeForm.change_CITY_INVEST = '0'
        }
        // this.addChangeForm.change_CITY_INVEST = res.change_CITY_INVEST
        if (res.check_CITY_INVEST === 1) {
          this.addChangeForm.check_CITY_INVEST = true
        } else {
          this.addChangeForm.check_CITY_INVEST = false
        }
        if (res.city_INVEST !== null && res.city_INVEST !== '') {
          this.addChangeForm.city_INVEST = res.city_INVEST + ''
        } else {
          this.addChangeForm.city_INVEST = ''
        }
        if (res.gics_TASK !== null && res.gics_TASK !== '') {
          this.addChangeForm.gics_TASK = res.gics_TASK + ''
        } else {
          this.addChangeForm.gics_TASK = ''
        }
        if (res.invest_TASK !== null && res.invest_TASK !== '') {
          this.addChangeForm.invest_TASK = res.invest_TASK + ''
        } else {
          this.addChangeForm.invest_TASK = ''
        }
        this.addChangeForm.support_SCORE_All = res.spt_SCORE
        if (res.taSptDetall !== '' && res.taSptDetall !== null) {
          for (const item of res.taSptDetall) {
            if (item.support_INDEX_ID === 10) {
              this.addChangeForm.spt_level_value = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE = item.support_SCORE
            } else if (item.support_INDEX_ID === 20) {
              this.addChangeForm.spt_level_value2 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE2 = item.support_SCORE
            } else if (item.support_INDEX_ID === 30) {
              this.addChangeForm.spt_level_value3 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE3 = item.support_SCORE
            } else if (item.support_INDEX_ID === 40) {
              this.addChangeForm.spt_level_value4 = item.support_LEVEL_ID
              this.addChangeForm.support_SCORE4 = item.support_SCORE
            }
          }
        }
      }
      this.addDialogVisible = true
    },
    // 信息变更记录查询
    async handleClick (row) {
      const { data: res } = await this.$http.get('querygics/history', {
        params: { company_ID: row.company_ID }
      })
      this.tableDataHistory = res
      this.recordDialogVisible = true
    },
    // 点击一条公司信息
    companyCurrentChange (val) {
      if (val != null) {
        if (val.task_ID !== 0) {
          this.addChangeForm.task_ID = val.task_ID
        }
        // this.addChangeForm.city_INVEST = val.city_INVEST
        this.addChangeForm.company_NAME = val.company_NAME
        this.addChangeForm.company_ID = val.company_ID
        this.addChangeForm.fiscal_YEAR = val.fiscal_YEAR
        // this.addChangeForm.is_CITY = val.is_CITY
        // this.addChangeForm.gics_CODE = val.gics_CODE
        // this.addChangeForm.orignal_GICS_CODE = val.gics_CODE
        // 设置查询条件从接口单独查询某个主体信息
        this.rowData = val
      }
    },
    // 取消并清空数据
    cancle () {
      this.addDialogVisible = false
    },
    // 关闭并清空数据
    closeRegDialog () {
      this.addDialogVisible = false
    }
  },
  computed: {
    sumSupportScore: function () {
      return Number(this.addChangeForm.support_SCORE) + Number(this.addChangeForm.support_SCORE2) + Number(this.addChangeForm.support_SCORE3) + Number(this.addChangeForm.support_SCORE4)
    }
  },
  data () {
    return {
      input: '',
      isDisabledGics: false,
      isDisabledVio: true,
      isTaskConfirm: true,
      queryInfo: {
        query: '',
        value1: '',
        // 筛选条件查询
        querySelectInput: 'gics',
        querySelect: '',
        // 当前页数
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10,
        beginDate: '',
        ednDate: ''
      },
      addChangeForm: {
        company_NAME: '',
        company_ID: '',
        task_ID: '',
        gics_CODES: '',
        gics_CODE: '',
        region_CODE: '',
        region_CODES: '',
        fiscal_YEAR: '',
        is_CITY: '',
        invest_TASK: '',
        gics_TASK: '',
        support_SCORE: '',
        support_SCORE2: '',
        support_SCORE3: '',
        support_SCORE4: '',
        city_INVEST: null,
        change_CITY_INVEST: null,
        check_CITY_INVEST: null,
        support_SCORE_All: null,
        spt_level_value: '',
        spt_level_value2: '',
        spt_level_value3: '',
        spt_level_value4: '',
        orignal_GICS_CODE: null,
        org_TYPE_NAME: ''
      },
      queryForm: {
        task_ID: null
      },
      totle: 0,
      // 添加用户对话框
      addDialogVisible: false,
      recordDialogVisible: false,
      tableData: [],
      tableDataHistory: [],
      rowData: null,
      spt_options: [],
      spt_level_options2: [],
      spt_level_options3: [],
      spt_level_options4: [],
      spt_level_options: [{
        value: '10',
        label: '公益性程度'
      },
      {
        value: '20',
        label: '股权结构'
      },
      {
        value: '30',
        label: '政府补助占营业利润总额的比重'
      },
      {
        value: '40',
        label: '增信措施'
      }],
      options: [],
      querySelectOptions: [{
        value: 'new',
        label: '新增主体'
      }, {
        value: 'history',
        label: '历史审核'
      }],
      fiscalOptions: [],
      regionOptions: [],
      violateOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      violateChangeOptions: [{
        value: '1',
        label: '是'
      }, {
        value: '0',
        label: '否'
      }],
      value: '',
      value1: '',
      rules: {
        gics_CODES: [
          { required: true, message: '财报行业', trigger: 'change' }
        ],
        city_INVEST: [
          { required: false, message: '是否城投', trigger: 'change' }
        ],
        gics_TASK: [
          { required: true, message: '行业任务', trigger: 'change' }
        ],
        invest_TASK: [
          { required: true, message: '城投任务', trigger: 'change' }
        ],
        change_CITY_INVEST: [
          { required: true, message: '城投变更', trigger: 'change' }
        ],
        region_CODES: [
          { required: true, message: '政府归属', trigger: 'change' }
        ],
        spt_level_value: [
          { required: true, message: '公益性程度', trigger: 'change' }
        ],
        spt_level_value2: [
          { required: true, message: '股权结构', trigger: 'change' }
        ],
        spt_level_value3: [
          { required: true, message: '政府补助占营业利润总额的比重', trigger: 'change' }
        ],
        spt_level_value4: [
          { required: true, message: '增信措施', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.getTableData()
  }
}
</script>
<style>
.el-dialog{
    display: flex;
    flex-direction: column;
    margin:0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    /*height:600px;*/
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
}
.el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
}
.el-table__body tr.current-row>td {
    color: #fff;
    background-color: #a2a4a7!important;
}
.el-row {
    margin-bottom: 20px;
  }
  .el-row:last-child {
    margin-bottom: 0;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
  .el-form-item {
    margin-top: -20px
  }
  .myspan {
    padding-left: 12px;
    color:blue;
  }
</style>
